import {
  Button,
  Checkbox,
  Divider,
  List,
  message,
  Modal,
  Space,
  Typography,
} from "antd";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useStore } from "../../store";
import {
  goToManageSecurityToken,
  updateUIState,
} from "../../~reusables/actions";
import {
  deleteAcceptedUser,
  deleteInvitedUser,
  getInvitedUserData,
  updateInvitedUser,
  upsertAcceptedUser,
} from "../../~reusables/firebase";
import { Flex } from "../atoms/Primitives";
import { InviteUserForm } from "./InviteUserForm";
import { UsersList } from "./UsersList";

const { Text } = Typography;

export const ManageUsers: React.FC = () => {
  const history = useHistory();
  const query = queryString.parse(window.location.search);
  const [modalVisible, setModalVisibility] = useState(true);
  const { user, acceptedUsers, invitedUsers } = useStore((state) => ({
    user: state.user,
    acceptedUsers: state.acceptedUsers,
    invitedUsers: state.invitedUsers,
  }));

  const closeModal = () => setModalVisibility(false);

  const { id } = query || {};
  useEffect(() => {
    async function updateUsersIHaveAcceptedInvitesFrom() {
      if (id && typeof id === "string" && user.email) {
        // remove the url search params (?id=xyz) related to the invite
        history.replace(window.location.pathname);

        try {
          const invitedUser = await getInvitedUserData(id, user.email);

          if (invitedUser) {
            const { sender } = invitedUser;
            await upsertAcceptedUser(id, sender);
            message.success(
              `You now have delegated access to ${sender.firstName}'s apps`
            );
          }
        } catch (err) {
          message.error("Invite is invalid");
        }
      }
    }

    updateUsersIHaveAcceptedInvitesFrom();
  }, [id, user.email]);

  return (
    <Modal
      visible={modalVisible}
      footer={null}
      closable={true}
      centered={true}
      onCancel={closeModal}
      afterClose={() => updateUIState(null)}
    >
      <Space direction="vertical" size="small">
        <UsersList
          title="Manage invited users"
          description={
            <>
              A <a onClick={() => goToManageSecurityToken()}>security token</a>{" "}
              is required to invite new users to your account. Invited users
              won't be able to create/delete apps under your account or manage
              billing information.
            </>
          }
        >
          <List
            dataSource={invitedUsers}
            header={
              <Flex css={{ flexDirection: "column", paddingBottom: "8px" }}>
                <InviteUserForm />
              </Flex>
            }
            renderItem={(item) => {
              return (
                <List.Item key={item.email}>
                  <List.Item.Meta title={item.name} description={item.email} />
                  <Flex
                    css={{
                      flexDirection: "column",
                      alignItems: "flex-end",
                      gap: "8px",
                    }}
                  >
                    <Button
                      size="small"
                      type="ghost"
                      danger
                      onClick={() => deleteInvitedUser(item.email)}
                    >
                      Remove user
                    </Button>
                    <Flex css={{ paddingBottom: "8px" }}>
                      <Checkbox
                        checked={item.permissions?.canBuild}
                        onChange={async (event) => {
                          await updateInvitedUser(item.email, {
                            permissions: { canBuild: event.target.checked },
                          });
                        }}
                      >
                        Can build
                      </Checkbox>
                      <Checkbox
                        checked={item.permissions?.canRelease}
                        onChange={async (event) => {
                          await updateInvitedUser(item.email, {
                            permissions: { canRelease: event.target.checked },
                          });
                        }}
                      >
                        Can release
                      </Checkbox>
                    </Flex>
                  </Flex>
                </List.Item>
              );
            }}
          />
        </UsersList>
        <Divider />
        <UsersList
          title="Manage delegated accounts"
          description="Manage the users who you have accepted invites from. "
        >
          <List
            dataSource={acceptedUsers}
            renderItem={(item) => (
              <List.Item key={item.id}>
                <List.Item.Meta
                  title={<a href="https://ant.design">{item.firstName}</a>}
                  description={item.email}
                />
                <Button
                  size="small"
                  type="ghost"
                  danger
                  onClick={() => deleteAcceptedUser(item.id)}
                >
                  Remove user
                </Button>
              </List.Item>
            )}
          />
        </UsersList>
      </Space>
    </Modal>
  );
};
