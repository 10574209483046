import React, { useState } from "react";
import { Button, Checkbox, Form, Input, message, Typography } from "antd";
import { callFirebaseFunction } from "../../~reusables/firebase";
import { useStore } from "../../store";
import merge from "lodash.merge";
import { UserIHaveSentInviteTo } from "@todesktop/shared";
import {
  authenticate,
  getSecurityTokenDetails,
} from "../../~reusables/firebase/webauthnUtils";
import confirm from "antd/lib/modal/confirm";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { goToManageSecurityToken } from "../../~reusables/actions";
import { Flex } from "../atoms/Primitives";

const { Text } = Typography;

const sendInviteUserEmail = callFirebaseFunction("sendInviteUserEmail");

export const InviteUserForm: React.FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const sender = useStore((state) => ({
    id: state.user.id,
    firstName: state.user.firstName,
    lastName: state.user.lastName,
    email: state.user.email,
  }));

  const capitalize = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const onFinish = async (values: {
    name: string;
    email: string;
    canBuild: boolean;
    canRelease: boolean;
  }) => {
    const fallbackName = capitalize(sender.email.split("@")[0] || "Unknown");

    const data: UserIHaveSentInviteTo = {
      name: values.name,
      email: values.email.toLowerCase(),
      permissions: { canBuild: values.canBuild, canRelease: values.canRelease },
      // left-hand side acts as defaults if right-hand keys are undefined
      sender: merge({ firstName: fallbackName, lastName: "" }, sender),
    };

    try {
      setLoading(true);
      const hasSecurityToken = await getSecurityTokenDetails();
      if (hasSecurityToken) {
        await sendInviteUserEmail({
          ...data,
          authentication: await authenticate(),
        });
        message.success(`Sent invite to ${data.email}`);
        form.resetFields();
      } else {
        const secTokenConfirm = confirm({
          title: "Inviting a user requires a security token",
          icon: <ExclamationCircleOutlined />,
          content: "Would you like to add a security token now?",
          onOk() {
            secTokenConfirm.destroy();
            goToManageSecurityToken();
          },
          onCancel() {
            secTokenConfirm.destroy();
          },
        });
      }
    } catch (err) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      name="basic"
      layout="vertical"
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      initialValues={{ email: "", name: "", canBuild: true, canRelease: false }}
    >
      <Flex css={{ width: "100%", gap: "16px" }}>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: "Please input an email", type: "email" },
          ]}
          style={{ flex: 1 }}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please input a name" }]}
          style={{ flex: 1 }}
        >
          <Input />
        </Form.Item>
      </Flex>

      <Flex
        css={{
          gap: "8px",
          paddingBottom: "16px",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Text strong>Permissions</Text>
        <Flex>
          <Form.Item name="canBuild" valuePropName="checked" noStyle>
            <Checkbox>Can build</Checkbox>
          </Form.Item>
          <Form.Item name="canRelease" valuePropName="checked" noStyle>
            <Checkbox>Can release</Checkbox>
          </Form.Item>
        </Flex>
      </Flex>
      <Button
        loading={loading}
        type="primary"
        htmlType="submit"
        style={{ width: "100%" }}
      >
        Invite user
      </Button>
    </Form>
  );
};
