// modules
import React, { Suspense, useEffect } from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import styled from "../~reusables/contexts/ThemeContext";

// components
import MaxWidthContainer from "../components/layout/MaxWidthContainer";
import ModalsContainer from "../components/layout/ModalsContainer";
import WebAppHeader from "../components/layout/WebAppHeader";
import { ProtectedApp } from "../components/molecules/ProtectedApp";
import ProtectedRoute from "../components/molecules/ProtectedRoute";
import NavigationContext, {
  appPathVariables,
  defaultPathVariables,
} from "../~reusables/contexts/NavigationContext";
import AppOverview from "./AppViews/AppOverview";
import Build from "./AppViews/Build";
import Builds from "./AppViews/Builds";
import { AppSettings } from "./AppViews/Settings/AppSettings";
import Authenticate, { AuthenticateDesktopApp } from "./Authenticate";
import Settings from "./TeamViews/Settings";

// logic
import { useChecklistUpdate } from "../components/edit/ChecklistItems";
import { TypedRoute } from "../components/Router";
import { appHasActiveSubscription, selectedApp, useStore } from "../store";
import { removeUIState, updateUIState } from "../~reusables/actions";
import { HEADER_PAGE_OFFSET } from "../~reusables/constants/metrics";
import Analytics from "./AppViews/Analytics";
import { DesktopCheckoutRedirect } from "./DesktopCheckoutRedirect";
import { ReleaseRouter } from "./routes/ReleaseRouter";
import { ApplicationList } from "./TeamViews/ApplicationList";

const SuperAdminDashboard = React.lazy(
  () => import("./SuperAdminViews/SuperAdminDashboard")
);

const AppLevelRouter: React.FC<RouteComponentProps> = () => {
  useChecklistUpdate();
  const app = useStore(selectedApp);
  const hasActiveSubscription = useStore(appHasActiveSubscription);
  const uiState = useStore((state) => state.uiState);

  useEffect(() => {
    if (app.appType === "electron") {
      if (!hasActiveSubscription) {
        updateUIState("subscribe");
      } else if (uiState === "subscribe") {
        removeUIState();
      }
    }
  }, [hasActiveSubscription]);

  return (
    <Switch>
      <Route
        exact
        path={`/apps/:id`}
        render={(routeProps) => <AppOverview {...routeProps} />}
      />
      <Route
        exact
        path={`/apps/:id/builds`}
        render={(routeProps) => <Builds {...routeProps} />}
      />
      <Route
        path={`/apps/:id/builds/:buildId`}
        render={(routeProps) => <Build {...routeProps} />}
      />
      <Route
        path={`/apps/:id/analytics`}
        render={(routeProps) => <Analytics {...routeProps} />}
      />
      <Route
        path={`/apps/:id/settings`}
        render={(routeProps) => <AppSettings {...routeProps} />}
      />
      {/* <Route
        path={`/apps/:id/crash-reports`}
        render={routeProps => <div>Crash Reports</div>}
      /> */}
    </Switch>
  );
};

const TeamLevelRouter: React.FC = () => {
  const selectedTeam = useStore((state) => state.selectedTeam);
  return (
    <Switch>
      <TypedRoute
        path={["/apps/:id/builds/:buildId/release"]}
        render={() => <ReleaseRouter />}
      />

      <Route
        exact
        path={[...defaultPathVariables, ...appPathVariables]}
        render={(routeProps) => {
          return (
            <StyledTeamLevelRouter>
              <WebAppHeader {...routeProps} />

              <MaxWidthContainer>
                <Route
                  exact
                  path="/"
                  render={(routeProps) => <ApplicationList {...routeProps} />}
                />
                {selectedTeam && (
                  <Route
                    exact
                    path="/settings"
                    render={(routeProps) => <Settings {...routeProps} />}
                  />
                )}
                <Route
                  exact
                  path="/super-admin"
                  render={() => (
                    <Suspense fallback={null}>
                      <SuperAdminDashboard />
                    </Suspense>
                  )}
                />
                <Route
                  path={`/apps/:id`}
                  render={(routeProps) => (
                    <ProtectedApp {...routeProps} component={AppLevelRouter} />
                  )}
                />
                <ModalsContainer {...routeProps} />
              </MaxWidthContainer>
            </StyledTeamLevelRouter>
          );
        }}
      />
      <Redirect to="/" />
    </Switch>
  );
};

const StyledTeamLevelRouter = styled.div`
  & > div {
    margin-top: -${HEADER_PAGE_OFFSET}px;
    padding-top: 0;
  }
`;

const WebAppV2: React.FC = () => {
  return (
    <NavigationContext>
      <Switch>
        <Route
          exact
          path="/signup"
          render={(routeProps) => (
            <Authenticate type="signup" {...routeProps} />
          )}
        />
        <Route
          exact
          path="/login"
          render={(routeProps) => <Authenticate type="login" {...routeProps} />}
        />
        <Route
          exact
          path="/desktop-app"
          render={(routeProps) => <AuthenticateDesktopApp {...routeProps} />}
        />
        <Route
          exact
          path="/desktop-checkout-redirect/:appId"
          render={(routeProps) => <DesktopCheckoutRedirect {...routeProps} />}
        />
        <ProtectedRoute path="/" component={TeamLevelRouter} />
        <Redirect to="/" />
      </Switch>
    </NavigationContext>
  );
};

export default WebAppV2;
