import firebase from "firebase/app";
import {
  initialState,
  update,
  store,
  selectedApp,
  subscribe,
  IGlobalState,
} from "../../store";
import {
  onUserApplicationUpdate,
  signInWithGoogle,
  signInWithTwitter,
  signInWithGithub,
  createPasswordUser,
  updateProfile,
  updateOrCreateCurrentUserData,
  createUserData,
  onUserAuth,
  getUserData,
  updateOrCreateUserData,
  getUserApplicationsData,
  onUserUpdate,
  getIdToken,
  getOwnAcceptedUsers,
  onAcceptedUsersUpdate,
  onInvitedUsersUpdate,
  callFirebaseFunction,
  signOut,
} from "../firebase";
import { saveApplication, addApp } from "./application";
import { Modal } from "antd";
import {
  track,
  reset as analyticsReset,
  identify as analyticsIdentify,
} from "../util/analytics";
import { authCancelledCodes, defaultUser } from "../constants";
import { IPasswordAccountForm } from "../../components/account/PasswordAccountForm";
import { resetBuildSubscriptions } from "./builds";
import { history } from "../util";
import { IUser, UserIHaveAcceptedInviteFrom } from "@todesktop/shared";
import { getCollection } from "../firebase/common";

let appStoreUnsubscribe: () => any;
let firebaseUnsubscribe: () => any;
let firebaseUserUnsubscribe: () => any;
let acceptedUsersUnsubscribe: () => any;
let invitedUsersUnsubscribe: () => any;
let teamUnsubscribe: () => any;
let authUnsubscribe: () => any;

export const resetAllState = () => {
  resetBuildSubscriptions();

  if (firebaseUserUnsubscribe) firebaseUserUnsubscribe();
  if (firebaseUnsubscribe) firebaseUnsubscribe();
  if (acceptedUsersUnsubscribe) acceptedUsersUnsubscribe();
  if (invitedUsersUnsubscribe) invitedUsersUnsubscribe();
  if (teamUnsubscribe) teamUnsubscribe();
  if (appStoreUnsubscribe) appStoreUnsubscribe();

  history.push("/");
  update((draft) => {
    Object.keys(initialState).forEach((key) => {
      draft[key] = initialState[key];
    });
  });
};

export const subscribeApplicationUpdates = (userId: string, appId: string) => {
  if (firebaseUnsubscribe) {
    firebaseUnsubscribe();
  }

  firebaseUnsubscribe = onUserApplicationUpdate(
    userId,
    appId,
    (updatedApp, snapshot) => {
      if (!updatedApp) {
        return;
      }
      update((draft) => {
        const app = selectedApp(draft);

        // update the outdated values in app
        Object.assign(app, updatedApp);
        if (app.meta.latestReleaseBuildId) {
          draft.buildState.releasedBuildId = app.meta.latestReleaseBuildId;
        }

        /**
         * Delete keys that are in app, but not in updatedApp. Nested keys are not deleted.
         *
         * fromCache === false gives us confidence that a full up-to-date copy of the data is being
         * returned from firestore so that we don't accidentally delete keys from the app in state
         */
        if (snapshot.metadata.fromCache === false && updatedApp.id) {
          const incomingKeys = Object.keys(updatedApp);
          Object.keys(app)
            .filter((k) => !incomingKeys.includes(k))
            .forEach((k) => delete app[k]);
        }
      });
    }
  );
};

function showModalError(message: string) {
  Modal.error({
    title: "Authentication Error",
    content: message,
  });
}

const catchAuthError = (e, provider: string) => {
  track({
    event: "Error On Signing Up",
    properties: {
      provider,
      error: e.message,
    },
  });
  if (!authCancelledCodes.includes(e.code)) {
    showModalError(e.message);
  }
};

export const userSignInWithGoogle = () => signInWithGoogle();
export const userSignInWithTwitter = () => signInWithTwitter();
export const userSignInWithGithub = () => signInWithGithub();

export const onGoogleAuth = (onAuthCb?: () => void) => async (
  isLogin = true
) => {
  try {
    if (isLogin) {
      await userSignInWithGoogle();
    } else {
      track({
        event: "Started Signing Up",
        properties: { provider: "google" },
      });
      await userSignInWithGoogle();
      track({ event: "Signed Up", properties: { provider: "google" } });
    }
    if (onAuthCb) onAuthCb();
  } catch (e) {
    catchAuthError(e, "google");
  }
};

export const onTwitterAuth = (onAuthCb?: () => void) => async (
  isLogin = true
) => {
  try {
    if (isLogin) {
      await userSignInWithTwitter();
    } else {
      track({
        event: "Started Signing Up",
        properties: { provider: "twitter" },
      });
      await userSignInWithTwitter();
      track({ event: "Signed Up", properties: { provider: "twitter" } });
    }
    if (onAuthCb) onAuthCb();
  } catch (e) {
    catchAuthError(e, "twitter");
  }
};

export const onGithubAuth = (onAuthCb?: () => void) => async (
  isLogin = true
) => {
  try {
    if (isLogin) {
      await userSignInWithGithub();
    } else {
      track({
        event: "Started Signing Up",
        properties: { provider: "github" },
      });
      await userSignInWithGithub();
      track({ event: "Signed Up", properties: { provider: "github" } });
    }
    if (onAuthCb) onAuthCb();
  } catch (e) {
    catchAuthError(e, "github");
  }
};

export const onPasswordSignup = (callback?: () => void) => async ({
  email,
  password,
  firstName,
  lastName,
}: IPasswordAccountForm) => {
  const authCredential = await createPasswordUser(email, password);
  track({ event: "Signed Up", properties: { provider: "password" } });
  // TODO: Populate profile by trigger (not manually)
  await updateProfile({
    displayName: `${firstName} ${lastName}`,
  });
  await updateOrCreateCurrentUserData(
    {
      firstName,
      lastName,
      email,
    },
    {
      isNewUser: authCredential.additionalUserInfo
        ? authCredential.additionalUserInfo.isNewUser
        : true,
    }
  );
  if (callback) callback();
};

const createUser = async (user: IUser) => {
  const result = {
    ...defaultUser,
    ...user,
    authInfo: {
      ...defaultUser.authInfo,
      ...user.authInfo,
    },
  };

  await createUserData(result.id, result, { isNewUser: true });
  return result;
};

let lastUser = null;
export const initializeOnUserAuth = () => {
  authUnsubscribe = onUserAuth(async (authUser) => {
    const hasLoggedOut = lastUser && authUser === undefined;
    const hasChangedUser = lastUser && authUser && lastUser.id !== authUser.id;
    if (hasLoggedOut || hasChangedUser) {
      resetAllState();
      analyticsReset();
    }
    lastUser = authUser;
    if (authUser) {
      let user = await getUserData(authUser.id);
      if (!user) {
        user = await createUser(authUser);
      }
      if (!user.id) {
        // Occasionally there was an earlier error where the id isn't populated
        console.warn("Repopulating user ID");
        await updateOrCreateUserData(
          authUser.id,
          { id: authUser.id },
          { isNewUser: false }
        );
        user.id = authUser.id;
      }

      const owner = { id: user.id, label: user.firstName, isOwner: true };
      update((draft) => {
        draft.user = user;
        if (user.isOldAccountThatNeedsNewPassword) {
          draft.uiState = "reset-password-on-upgraded-account";
        }

        Object.entries(draft.appToUser).forEach(([appId, user]) => {
          if (user.isOwner) {
            draft.appToUser[appId] = owner;
          }
        });
      });

      getIdToken().then((idTokenResult) => {
        console.log({ idTokenResult });
        analyticsIdentify(authUser.id, idTokenResult, {
          email: authUser.email,
          firstName: authUser.firstName,
          lastName: authUser.lastName,
        });

        if (idTokenResult.claims.admin) {
          update((draft) => {
            draft.user.isAdmin = true;
          });
        }

        if (idTokenResult.claims.impersonator) {
          update((draft) => {
            draft.user.isImpersonator = true;
          });
        }
      });

      const rawState = store.getRawState();
      const { apps, appToUser } = rawState;
      if (apps.length > 0) {
        apps.forEach(async (app) => {
          const user = appToUser[app.id];
          if (user) {
            await saveApplication.run({ id: user.id, app });
          }
        });
        if (rawState.selectedApp) {
          const { id } = selectedApp(rawState);
          const user = appToUser[id];
          if (user) subscribeApplicationUpdates(user.id, id);
        }
      }

      const acceptedUsers = await getCollection<UserIHaveAcceptedInviteFrom>(
        getOwnAcceptedUsers(user.id)
      );

      const list = [
        owner,
        ...acceptedUsers.map((user) => ({
          label: user.firstName,
          id: user.id,
          isOwner: false,
        })),
      ];

      for (const item of list) {
        try {
          const fetchedApps = await getUserApplicationsData(item.id);
          fetchedApps.forEach((app) => addApp(app, item));
        } catch (err) {
          // No applications
          // TODO: Should treat other errors differently
        }
      }

      invitedUsersUnsubscribe = onInvitedUsersUpdate(user.id, (invitedUsers) =>
        update((draft) => {
          draft.invitedUsers = invitedUsers;
        })
      );

      acceptedUsersUnsubscribe = onAcceptedUsersUpdate(
        user.id,
        (acceptedUsers, snapshot) => {
          update((draft) => {
            draft.acceptedUsers = acceptedUsers;
          });

          snapshot.docChanges().forEach(async (change) => {
            try {
              if (change.type === "added") {
                const { id, firstName } = change.doc.data();
                const appUser = { id, isOwner: false, label: firstName };
                const apps = await getUserApplicationsData(id);

                apps.forEach((app) => addApp(app, appUser));
              } else if (change.type === "removed") {
                const apps = await getUserApplicationsData(change.doc.id);

                apps.forEach((app) => {
                  const { selectedApp } = store.getRawState();

                  if (selectedApp && selectedApp === app.id) history.push("/");

                  update((draft) => {
                    const idx = draft.apps.findIndex((app) => app.id);
                    if (idx > -1) {
                      draft.apps.splice(idx, 1);
                      draft.selectedApp = draft.apps[0]?.id;
                    }

                    draft.appToUser[app.id] = undefined;
                  });
                });
              }
            } catch (err) {
              console.log("err", err);
            }
          });
        }
      );

      appStoreUnsubscribe = subscribe(
        (state: IGlobalState) => state.user && state.selectedApp,
        (appId?: string) => {
          const id = store.getRawState().appToUser[appId]?.id;

          return subscribeApplicationUpdates(id || user.id, appId);
        }
      );

      if (firebaseUserUnsubscribe) {
        firebaseUserUnsubscribe();
      }
      firebaseUserUnsubscribe = onUserUpdate(user.id, (updatedUser) => {
        const oldUser = store.getRawState().user;
        update((draft) => {
          draft.user = { ...oldUser, ...updatedUser };
        });
      });
    }

    update((draft) => {
      draft.userDataLoaded = true;
    });
  });
};

export const unsubscribeOnUserAuth = () => authUnsubscribe();

export const impersonateUser = async (email, authentication) => {
  const loginAdmin = callFirebaseFunction("loginAdmin");
  const { data } = await loginAdmin({ email, authentication });

  // make sure to reset all state and sign out before impersonating a user
  // this is just an extra safe way of making sure state is separated between accounts
  resetAllState();
  analyticsReset();
  await signOut();
  await firebase.auth().signInWithCustomToken(data.token);
};
