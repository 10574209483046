import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, message, Modal } from "antd";
import confirm from "antd/lib/modal/confirm";
import React, { useEffect, useState } from "react";
import { useStore } from "../../store";
import {
  goToManageSecurityToken,
  impersonateUser,
  removeUIState,
  updateUIState,
} from "../../~reusables/actions";
import {
  authenticate,
  getSecurityTokenDetails,
} from "../../~reusables/firebase/webauthnUtils";
import { Flex } from "../atoms/Primitives";

export const AdminLogin: React.FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const uiState = useStore((state) => state.uiState);

  return (
    <Modal
      open={uiState === "admin-login"}
      footer={null}
      closable={true}
      centered={true}
      onCancel={() => {
        removeUIState();
      }}
      title="Admin Login"
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={async ({ email }) => {
          try {
            setLoading(true);
            const hasSecurityToken = await getSecurityTokenDetails();
            if (hasSecurityToken) {
              await impersonateUser(email, await authenticate());
              form.resetFields();
              removeUIState();
            } else {
              const secTokenConfirm = confirm({
                title: "Logging in as another user requires a security token",
                icon: <ExclamationCircleOutlined />,
                content: "Would you like to add a security token now?",
                onOk() {
                  secTokenConfirm.destroy();
                  goToManageSecurityToken();
                },
                onCancel() {
                  secTokenConfirm.destroy();
                },
              });
            }
          } catch (err) {
            message.error(err.message);
          } finally {
            setLoading(false);
          }
        }}
      >
        <Flex css={{ width: "100%", gap: "16px" }}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Please input an email",
                type: "email",
              },
            ]}
            style={{ flex: 1 }}
          >
            <Input />
          </Form.Item>
        </Flex>
        <Button
          loading={loading}
          type="primary"
          htmlType="submit"
          style={{ width: "100%" }}
        >
          Login
        </Button>
      </Form>
    </Modal>
  );
};
