import {
  WindowsEVOnboardingProvider,
  WindowsEVOnboardingSteps,
  WindowsHSMCertType,
} from "@todesktop/shared";
import { Button, Form, message, Skeleton, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import {
  $completedEVOnboardingSteps,
  $windowsCertExists,
  $windowsEVOnboardingProvider,
  selectedApp,
  useStore,
} from "../../../store";
import {
  isFileChangeError,
  toBase64,
  updateWindowsCert,
  updateWindowsEVOnboardingStep,
} from "../../../~reusables/actions";
import { callFirebaseFunction } from "../../../~reusables/firebase";
import useForm from "../../../~reusables/hooks/useForm";
import certPlaceholder from "../../../~reusables/images/certificate.png";
import { CertPlatform, IHSMCertificate } from "../../../~reusables/types";
import { track } from "../../../~reusables/util/analytics";
import { windowsFileCertValidation } from "../../../~reusables/util/validationRules";
import { FormInput } from "../../atoms/FormUtils";
import { Flex } from "../../atoms/Primitives";
import { Space } from "../../atoms/Space";
import { UploadField } from "../../atoms/UploadField";
import { ChecklistTabs, ChecklistTabsPane } from "../ChecklistTabs";
import {
  ChooseProvider,
  EVStepsCheckbox,
  GenerateCert,
  GetVerified,
  OrderCert,
  UploadCert,
} from "./EVOnboarding";
import { genFormEvent, TablessCertPane, useCertTab } from "./SharedCertUtils";

interface IWindowsTabProps {
  hsmCertDetails: IHSMCertificate | null;
  isCertInUse: boolean;
  setEditCertMode: React.Dispatch<React.SetStateAction<boolean>>;
  setHSMCertDetails: React.Dispatch<React.SetStateAction<IHSMCertificate>>;
}

const getHSMCert = callFirebaseFunction("getHSMCert");

export const WindowsCertificate: React.FC = () => {
  const [hsmCertDetails, setHSMCertDetails] = useState<IHSMCertificate>();
  const [isCertLoading, setCertLoading] = useState(true);
  const { providerFormatted } = useStore($windowsEVOnboardingProvider);
  const windowsCertExists = useStore($windowsCertExists);
  const [isEditCertMode, setEditCertMode] = useState(!windowsCertExists);
  const { customWindowsCodeSign: cwcs, id } = useStore(selectedApp);

  const shouldUseEv = cwcs && cwcs.hsmCertType === "ev";
  const shouldUseFile = cwcs && cwcs.hsmCertType === "file";

  const [activeTab, setActiveTab] = useState(
    shouldUseEv ? WindowsHSMCertType.ev : WindowsHSMCertType.file
  );

  useEffect(() => {
    if (cwcs?.hsmCertName) {
      getHSMCert({ hsmCertName: cwcs.hsmCertName, appId: id, type: "windows" })
        .then((res) => {
          setHSMCertDetails(res.data);
        })
        .catch((err) => message.error(err.message))
        .finally(() => setCertLoading(false));
    } else {
      setHSMCertDetails(null);
      setCertLoading(false);
    }
  }, [id, cwcs?.hsmCertName]);

  if (isCertLoading) {
    return <Skeleton />;
  }

  const shouldDisplayTabs = isEditCertMode || (!shouldUseEv && !shouldUseFile);
  return (
    <Tabs
      tabBarStyle={{ display: shouldDisplayTabs ? "block" : "none" }}
      activeKey={activeTab}
      onChange={(value: WindowsHSMCertType) => setActiveTab(value)}
      items={[
        {
          key: WindowsHSMCertType.file,
          label: "File",
          children: (
            <FileTab
              isCertInUse={windowsCertExists && shouldUseFile}
              hsmCertDetails={hsmCertDetails}
              setHSMCertDetails={setHSMCertDetails}
              setEditCertMode={setEditCertMode}
            />
          ),
        },
        {
          key: WindowsHSMCertType.ev,
          label: `EV (${providerFormatted})`,
          children: (
            <EVTab
              isCertInUse={windowsCertExists && shouldUseEv}
              hsmCertDetails={hsmCertDetails}
              setEditCertMode={setEditCertMode}
              setHSMCertDetails={setHSMCertDetails}
            />
          ),
        },
      ]}
    ></Tabs>
  );
};
const addStandardCodeSigningCert = callFirebaseFunction(
  "addStandardCodeSigningCert"
);

const FileTab: React.FC<IWindowsTabProps> = ({
  hsmCertDetails,
  isCertInUse,
  setEditCertMode,
  setHSMCertDetails,
}) => {
  const app = useStore(selectedApp);
  const { id, name } = app;

  const callback = async ({
    pfxBase64,
    certPasswordWin,
  }: {
    pfxBase64: string;
    certPasswordWin: string;
    certFile: string;
  }) => {
    track({
      event: "Update Certificate",
      properties: {
        action: "save",
        platform: CertPlatform.Windows,
        provider: "file",
      },
    });

    try {
      const {
        data: { certType, hsmCertType, hsmCertName, certDetails },
      } = await addStandardCodeSigningCert({
        appName: name,
        appId: id,
        password: certPasswordWin,
        pfxBase64,
      });
      await updateWindowsCert(app, "windows", {
        certType,
        hsmCertType,
        hsmCertName,
      });
      setHSMCertDetails(certDetails);
      message.success("Windows Certificate saved");
    } catch (err) {
      message.error(err.message);
    }
  };

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    isSubmitting,
    formError,
    resetForm,
  } = useForm(
    { certPasswordWin: "", certFile: "", pfxBase64: "" },
    callback,
    windowsFileCertValidation
  );

  const [certTab, setCertTab] = useCertTab(isCertInUse, resetForm);

  useEffect(() => {
    if (formError) {
      message.error(formError);
    }
  }, [formError]);

  return (
    <TablessCertPane
      tabUtils={{ certTab, setCertTab, setEditCertMode, isCertInUse }}
      viewComponentProps={{ cert: hsmCertDetails, target: "windows" }}
      addComponent={
        <Form layout="vertical" onFinish={handleSubmit}>
          <FormInput
            error={errors.certPasswordWin}
            label="Certificate Password"
            name="certPasswordWin"
            onChange={handleChange}
            value={values.certPasswordWin}
            disabled={isSubmitting}
            password
          />
          <UploadField
            error={errors.certFile}
            isUploading={false}
            uploadPercent={values.certFile ? 100 : 0}
            icon={values.certFile ? certPlaceholder : null}
            label="Certificate file"
            uploadedName={values.certFile ? values.certFile : ""}
            accept=".p12,.pfx"
            beforeUpload={() => false}
            onChange={({ file }) => {
              if (!file || isFileChangeError(file)) return;

              toBase64((file as unknown) as Blob)
                .then((base64) => {
                  handleChange(genFormEvent("pfxBase64", base64));
                  handleChange(genFormEvent("certFile", file.name));
                })
                .catch((err) => message.error(err.message, 3));
            }}
          />
          <Flex justifyContent="center" css={{ paddingTop: "8px" }}>
            <Button
              htmlType="submit"
              loading={isSubmitting}
              size="large"
              type="primary"
            >
              Save changes
            </Button>
          </Flex>
        </Form>
      }
    />
  );
};

enum EVTabPanes {
  ChooseProvider = "ChooseProvider",
  OrderCert = "OrderCert",
  GetVerified = "GetVerified",
  GenerateCert = "GenerateCert",
  UploadCert = "UploadCert",
}

const EVTab: React.FC<IWindowsTabProps> = ({
  hsmCertDetails,
  isCertInUse,
  setEditCertMode,
  setHSMCertDetails,
}) => {
  const [certTab, setCertTab] = useCertTab(isCertInUse);
  const [activeTab, setActiveTab] = useState(EVTabPanes.ChooseProvider);
  const completedEVOnboardingSteps = useStore($completedEVOnboardingSteps);
  const { provider } = useStore($windowsEVOnboardingProvider);

  const onboardingSteps = [
    {
      id: WindowsEVOnboardingSteps.hasChosenProvider,
      tab: "Choose Provider",
      tabKey: EVTabPanes.ChooseProvider,
      Component: <ChooseProvider />,
    },
    ...(provider !== WindowsEVOnboardingProvider.other
      ? [
          {
            id: WindowsEVOnboardingSteps.hasOrderedCert,
            tab: "Order Cert",
            tabKey: EVTabPanes.OrderCert,
            Component: <OrderCert />,
          },
          {
            id: WindowsEVOnboardingSteps.hasBeenVerified,
            tab: "Get Verified",
            tabKey: EVTabPanes.GetVerified,
            Component: <GetVerified />,
          },
        ]
      : []),
    {
      id: WindowsEVOnboardingSteps.hasGeneratedCert,
      tab: "Generate Cert",
      tabKey: EVTabPanes.GenerateCert,
      Component: <GenerateCert />,
    },
    {
      id: WindowsEVOnboardingSteps.hasUploadedCert,
      tab: "Upload Cert",
      tabKey: EVTabPanes.UploadCert,
      Component: <UploadCert setHSMCertDetails={setHSMCertDetails} />,
    },
  ];

  return (
    <TablessCertPane
      tabUtils={{ certTab, setCertTab, setEditCertMode, isCertInUse }}
      viewComponentProps={{ cert: hsmCertDetails, target: "windows" }}
      addComponent={
        <ChecklistTabs
          onChange={(tab: EVTabPanes) => setActiveTab(tab)}
          activeKey={activeTab}
        >
          {onboardingSteps.map(({ Component, tabKey, tab, id }) => {
            const isChecked = completedEVOnboardingSteps[id];
            return (
              <ChecklistTabsPane
                key={tabKey}
                tabKey={tabKey}
                tab={tab}
                extra={
                  <EVStepsCheckbox
                    isActive={activeTab === tabKey}
                    checked={isChecked}
                    onChange={(e) =>
                      updateWindowsEVOnboardingStep(id, e.target.checked)
                    }
                  />
                }
              >
                <Space
                  width="100%"
                  direction="column"
                  justifyContent="space-between"
                  size="md"
                  minHeight="100%"
                  align="flex-start"
                >
                  {Component}
                  <Flex justifyContent="space-between">
                    <div />
                    <Button
                      onClick={async () => {
                        await updateWindowsEVOnboardingStep(id, !isChecked);

                        const step = onboardingSteps.findIndex(
                          (step) => step.id === id
                        );

                        if (step !== onboardingSteps.length - 1) {
                          setActiveTab(onboardingSteps[step + 1].tabKey);
                        }
                      }}
                      type={isChecked ? "default" : "primary"}
                    >
                      {isChecked ? "Mark as incomplete" : "Complete step"}
                    </Button>
                  </Flex>
                </Space>
              </ChecklistTabsPane>
            );
          })}
        </ChecklistTabs>
      }
    />
  );
};
