import { IApp, IApp2 } from "@todesktop/shared";
import { Button, Empty, Typography } from "antd";
import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { Box, Flex } from "../../components/atoms/Primitives";
import { StyledAntdCard } from "../../components/atoms/StyledAntdCard";
import { ApplicationGroupTag } from "../../components/molecules/AppGroupDropdownMenu";
import { AppUser, useStore } from "../../store";
import { goToCreateNewAppModal } from "../../~reusables/actions";
import styled, { useTheme } from "../../~reusables/contexts/ThemeContext";
import { track } from "../../~reusables/util/analytics";

export const ApplicationList: React.FC<RouteComponentProps> = () => {
  const record = useApplicationsRecord();
  const userDataLoaded = useStore((state) => state.userDataLoaded);

  return (
    <main>
      {Object.values(record).map(({ id, isOwner, label, record }) => {
        const apps = Object.values(record);
        return (
          <StyledAntdCard
            key={id}
            loading={!userDataLoaded}
            title={isOwner ? "Apps Overview" : `${label}'s apps`}
            bodyStyle={{ padding: 0 }}
            extra={
              isOwner ? (
                <Button
                  type="primary"
                  onClick={() => {
                    track({ event: "Create New App Modal" });
                    goToCreateNewAppModal();
                  }}
                >
                  Create new app
                </Button>
              ) : null
            }
          >
            {apps.length ? (
              apps.map((data) => {
                return <AppPreview key={data.app.id} data={data} />;
              })
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </StyledAntdCard>
        );
      })}
    </main>
  );
};

const { Text } = Typography;

const AppPreview: React.FC<{ data: { app: IApp; children: IApp[] } }> = ({
  data: { app, children },
}) => {
  const { colors } = useTheme();
  return (
    <Box>
      <FlexLink
        to={`/apps/${app.id}`}
        css={{
          flexDirection: "column",
          borderTop: `1px solid ${colors.greys[6]}`,
        }}
      >
        <Flex
          css={{
            padding: "10px 28px 10px 20px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Preview app={app}>
            {children.length ? <ApplicationGroupTag app={app} /> : null}
          </Preview>
        </Flex>
      </FlexLink>
      {children.map((app) => (
        <FlexLink
          key={app.id}
          to={`/apps/${app.id}`}
          css={{
            background: colors.lightBackground,
            borderTop: `1px dashed ${colors.greys[6]}`,
            padding: "10px 28px 10px 54px",
            gap: "8px",
          }}
        >
          <Preview app={app}>
            <ApplicationGroupTag app={app} />
          </Preview>
        </FlexLink>
      ))}
    </Box>
  );
};

const Preview: React.FC<{ app: IApp | IApp2 }> = ({ app, children }) => {
  let details: { title: string; subtitle: string; icon: string };
  if (app.schemaVersion >= 2) {
    const { desktopApp } = app as IApp2;
    details = {
      icon: desktopApp.icon,
      title: desktopApp.name,
      subtitle: "ToDesktop Builder",
    };
  } else {
    const { name, icon, appType } = app as IApp;
    details = {
      title: name,
      icon: icon,
      subtitle:
        appType === "electron"
          ? "Electron Platform"
          : `ToDesktop Web${appType === "menubar" ? " (menubar)" : ""}`,
    };
  }

  return (
    <Flex
      css={{
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Flex css={{ alignItems: "center", gap: "8px", overflow: "hidden" }}>
        <img src={details.icon} width="28px" height="28px" />
        <Text
          style={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {details.title}
        </Text>
        {children}
      </Flex>
      <Text type="secondary" style={{ fontSize: 12 }}>
        {details.subtitle}
      </Text>
    </Flex>
  );
};

const FlexLink = styled(Link)`
  display: flex;
  cursor: pointer;

  &:hover {
    background: ${(p) => p.theme.colors.greys[7]};
  }
`;

type UserAppsRecord = Record<string, AppUser & { record: ApplicationsRecord }>;
type ApplicationsRecord = Record<string, { app: IApp; children: IApp[] }>;

const useApplicationsRecord = () => {
  const { apps, appToUser, user } = useStore((state) => ({
    apps: state.apps,
    appToUser: state.appToUser,
    user: state.user,
  }));

  const appsRecord: ApplicationsRecord = {};
  const parentApps: IApp[] = [];
  const childApps: IApp[] = [];
  for (const app of apps) {
    if (app.parent) {
      childApps.push(app);
    } else {
      parentApps.push(app);
    }
  }

  for (const app of [...parentApps, ...childApps]) {
    if (app.parent && appsRecord[app.parent.id]) {
      appsRecord[app.parent.id].children.push(app);
    } else if (app.id) {
      appsRecord[app.id] = { app, children: [] };
    }
  }

  const userAppsRecord: UserAppsRecord = {
    [user.id]: {
      id: user.id,
      label: user.firstName,
      record: {},
      isOwner: true,
    },
  };

  for (const id in appToUser) {
    const user = appToUser[id];
    if (!user) continue;

    userAppsRecord[user.id] = { ...user, record: {} };
  }

  for (const appId in appsRecord) {
    const user = appToUser[appId];
    const record = appsRecord[appId];
    if (userAppsRecord[user?.id]) {
      userAppsRecord[user.id].record[appId] = record;
    }
  }

  return userAppsRecord;
};
