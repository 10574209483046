import { InfoCircleOutlined } from "@ant-design/icons";
import {
  IApp,
  proPlan,
  scalePlan,
  PortalConfigKey,
  products,
  IApp2,
} from "@todesktop/shared";
import type { MenuProps } from "antd";
import {
  Badge,
  Button,
  Dropdown,
  Form,
  Input,
  Modal,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import {
  $hasPlanAccess,
  $leaderApp,
  AppUser,
  selectedApp,
  store,
  useStore,
} from "../../store";
import {
  addApp,
  createCustomerCheckoutSession,
  createCustomerPortalSession,
  updateAndDispatchApp,
} from "../../~reusables/actions";
import {
  callFirebaseFunction,
  saveUserApplicationData,
  setUserData,
} from "../../~reusables/firebase";
import { Box, Flex } from "../atoms/Primitives";
import { PlanBadge } from "./PlanBadge";
import { stage } from "../../~reusables/firebase/config";

interface State {
  dropdownOpen: boolean;
  subscriptionModal: boolean;
  applicationModal: boolean;
  app: IApp | null;
}

const initialState: State = {
  dropdownOpen: false,
  subscriptionModal: false,
  applicationModal: false,
  app: null,
};

const { Title, Text } = Typography;

export const AppGroupDropdownMenu: React.FC = () => {
  const [state, setState] = useState<State>(initialState);
  const history = useHistory();
  const apps = useStore((state) => state.apps);
  const appToUser = useStore((state) => state.appToUser);
  const leaderApp = useStore($leaderApp);
  const app = useStore(selectedApp);
  const user = useStore((state) => state.user);
  useEffect(() => setState(initialState), [app?.id]);

  const items = useMemo(() => {
    if (!leaderApp) return [];
    const children = apps.filter((app) => app.parent?.id === leaderApp.id);

    const existingLeaderIds = new Set(
      apps.filter((app) => app?.parent?.id).map((app) => app.parent.id)
    );
    const candidates = apps.filter((app) => {
      if (existingLeaderIds.has(app.id)) return false;

      const isElectronApp = app.appType === "electron";
      return app.id !== leaderApp.id && !app.parent && isElectronApp;
    });

    const plan = children.length >= 2 ? scalePlan : proPlan;
    const hasAccess = $hasPlanAccess(plan)(store.getRawState());

    const items: MenuProps["items"] = [
      {
        key: leaderApp.id,
        label: (
          <AppPreview app={leaderApp}>
            <ApplicationGroupTag app={leaderApp} />
          </AppPreview>
        ),
        onClick: () => history.push(`/apps/${leaderApp.id}`),
      },
      { key: "first-divider", type: "divider" },
    ];

    if (children.length) {
      children.forEach((app) => {
        items.push(
          {
            key: app.id,
            label: (
              <AppPreview app={app}>
                <ApplicationGroupTag app={app} />
              </AppPreview>
            ),
            onClick: () => history.push(`/apps/${app.id}`),
          },
          {
            key: `${app.id}-edit`,
            label: "Edit relationship",
            onClick: () => {
              setState((prev) => ({
                ...prev,
                app,
                dropdownOpen: false,
                applicationModal: true,
              }));
            },
          },
          { key: `${app.id}-divider`, type: "divider" }
        );
      });
    } else {
      items.push(
        {
          key: "empty",
          disabled: true,
          label:
            "Adding an application into this group allows it to extend properties from the parent app.",
        },
        { key: `empty-divider`, type: "divider" }
      );
    }

    items.push({
      key: "create",
      label: (
        <Flex
          css={{ display: "inline-flex", alignItems: "center", gap: "4px" }}
        >
          <PlanBadge plan={plan} showLabel={false} />
          Add new app to {leaderApp.name}
        </Flex>
      ),
      onClick: () => {
        setState((prev) => {
          return {
            ...prev,
            ...(hasAccess
              ? { applicationModal: true }
              : { subscriptionModal: true }),
            app: null,
            dropdownOpen: false,
          };
        });
      },
    });

    if (candidates.length) {
      items.push({
        key: "connect",
        label: (
          <Flex
            css={{ display: "inline-flex", alignItems: "center", gap: "4px" }}
          >
            <PlanBadge plan={plan} showLabel={false} />
            Connect existing app to {leaderApp.name}
          </Flex>
        ),
        children: candidates.map((app) => ({
          key: app.id,
          label: <AppPreview app={app} />,
          onClick: () => {
            setState((prev) => {
              return {
                ...prev,
                ...(hasAccess
                  ? { applicationModal: true }
                  : { subscriptionModal: true }),
                app,
                dropdownOpen: false,
              };
            });
          },
        })),
      });
    }

    return items;
  }, [leaderApp, apps, appToUser, history]);

  if (!leaderApp || leaderApp.appType !== "electron") return null;

  const modalProps: ModalProps = {
    state,
    setState,
    appUser: appToUser[leaderApp.id],
  };

  const count = apps.filter((app) => app.parent?.id === leaderApp.id).length;
  return (
    <Fragment>
      <Dropdown
        overlayStyle={{ width: 340 }}
        open={state.dropdownOpen}
        onOpenChange={async (dropdownOpen) => {
          setState((prev) => ({ ...prev, dropdownOpen }));
          if (dropdownOpen && user && !user?.seenApplicationGroupsTutorial) {
            await setUserData(user.id, { seenApplicationGroupsTutorial: true });
          }
        }}
        menu={{ items }}
        trigger={["click"]}
      >
        <Badge
          size="small"
          count={
            count ? (
              count
            ) : !user?.seenApplicationGroupsTutorial ? (
              <Tooltip title="ToDesktop now supports Staging apps by allowing apps to extend properties from other apps.">
                <InfoCircleOutlined style={{ color: "rgb(255, 255, 255)" }} />
              </Tooltip>
            ) : undefined
          }
          offset={[4, 4]}
          style={{ backgroundColor: "transparent" }}
        >
          <Flex
            css={{
              flexDirection: "column",
              transition: "color 250ms",
              color: "rgba(255, 255, 255, 0.75)",
              cursor: "pointer",
              "&:hover": {
                color: "rgb(255, 255, 255)",
              },
            }}
          >
            <ChevronUp />
            <ChevronDown />
          </Flex>
        </Badge>
      </Dropdown>
      <ConfigureApplicationModal {...modalProps} />
      <UpgradeSubscriptionModal {...modalProps} />
    </Fragment>
  );
};

interface ModalProps {
  appUser: AppUser;
  state: State;
  setState: React.Dispatch<React.SetStateAction<State>>;
}

const ConfigureApplicationModal: React.FC<ModalProps> = ({
  appUser,
  state,
  setState,
}) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [form] = Form.useForm();
  useEffect(() => form.resetFields(), [state.app?.id]);

  const leaderApp = useStore($leaderApp);
  const type = !state.app
    ? "Create"
    : state.app?.parent?.id === leaderApp.id
    ? "Update"
    : "Connect";

  const onClose = () => {
    setState((prev) => ({
      ...prev,
      app: null,
      applicationModal: false,
      dropdownOpen: true,
    }));
  };

  return (
    <Modal
      title={state.app ? `${type} ${state.app.name}` : `${type} Application`}
      mask={false}
      open={state.applicationModal}
      footer={null}
      onCancel={onClose}
    >
      <Form
        name="basic"
        layout="vertical"
        form={form}
        onFinish={async ({ relationship, name }) => {
          if (loading) return;
          setLoading(true);

          const parent = {
            id: leaderApp.id,
            relationshipToParent: relationship,
          };
          switch (type) {
            case "Create":
              const result = await createNewApp({ name, appType: "electron" });

              const app: IApp = {
                ...leaderApp,
                id: result.data.id,
                name: result.data.name,
                meta: { publishedVersions: {} },
                parent,
              };

              // don't duplicate the following properties
              delete app.customDomain;
              delete app.subscription;
              delete app.customMacCodeSign;
              delete app.customWindowsCodeSign;

              await saveUserApplicationData(appUser.id, app.id, app);
              addApp(app, appUser);
              onClose();
              history.push(`/apps/${app.id}`);
              break;
            case "Connect":
            case "Update":
              await updateAndDispatchApp(state.app.id, { parent });
              onClose();
              break;
          }

          setLoading(false);
        }}
        onFinishFailed={() => {}}
        autoComplete="off"
      >
        {type === "Create" && (
          <Form.Item label="Name" name="name" rules={[{ required: true }]}>
            <Input placeholder="Application" />
          </Form.Item>
        )}

        <Form.Item
          label={`Relationship to ${leaderApp.name}`}
          name="relationship"
          rules={[{ required: true }]}
        >
          <Input placeholder="Staging" />
        </Form.Item>
        <Form.Item>
          <Flex
            css={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              {type === "Update" && (
                <Button
                  danger
                  onClick={async () => {
                    await updateAndDispatchApp(state.app.id, {
                      parent: null,
                    });
                    onClose();
                  }}
                >
                  Disconnect
                </Button>
              )}
            </Box>
            <Space>
              <Button onClick={onClose}>Cancel</Button>
              <Button type="primary" htmlType="submit" loading={loading}>
                {type}
              </Button>
            </Space>
          </Flex>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const UpgradeSubscriptionModal: React.FC<ModalProps> = ({
  state,
  setState,
}) => {
  const [loading, setLoading] = useState(false);
  const leaderApp = useStore($leaderApp);
  const leaderAppUser = useStore((state) => state.appToUser)[leaderApp?.id];
  const isNotOwner = !leaderAppUser?.isOwner;

  const onClose = () => {
    setState((prev) => ({
      ...prev,
      app: null,
      subscriptionModal: false,
      dropdownOpen: true,
    }));
  };

  return (
    <Modal
      mask={false}
      open={state.subscriptionModal}
      footer={null}
      onCancel={onClose}
    >
      <Space direction="vertical">
        <Title level={4}>Upgrade subscription</Title>
        <Text>
          Upgrading your {leaderApp?.name} subscription enables the creation of
          Staging and Testing apps that can be added to an application group:
        </Text>
        <ul>
          <li>
            <b>Pro plans</b> support two additional apps.
          </li>
          <li>
            <b>Scale plans</b> support ten additional apps.
          </li>
        </ul>
        <Space direction="vertical" />
        {isNotOwner && (
          <Text>
            Only the application owner ({leaderAppUser?.label}) can upgrade a
            subscription.
          </Text>
        )}
        <Button
          disabled={isNotOwner}
          type="primary"
          loading={loading}
          onClick={async () => {
            if (loading) return;
            try {
              setLoading(true);

              if (leaderApp.subscription) {
                await createCustomerPortalSession({
                  configuration:
                    stage === "prod"
                      ? PortalConfigKey.CLIUpgradeProd
                      : PortalConfigKey.CLIUpgradeDev,
                  flowData: {
                    type: "subscription_update",
                    subscription_update: {
                      subscription: leaderApp?.subscription?.subscriptionId,
                    },
                  },
                });
              } else {
                await createCustomerCheckoutSession({
                  appId: leaderApp.id,
                  priceId:
                    products[stage].cli.performance.prices.monthly_300.id,
                });
              }
              onClose();
            } finally {
              setLoading(false);
            }
          }}
        >
          Upgrade subscription
        </Button>
      </Space>
    </Modal>
  );
};

const AppPreview: React.FC<{ app: IApp }> = ({ app, children }) => {
  return (
    <Flex
      css={{
        alignItems: "center",
        justifyContent: "space-between",
        gap: "16px",
      }}
    >
      <Flex css={{ alignItems: "center", gap: "4px" }}>
        <img src={app.icon} width={24} height={24} />
        {app.name}
      </Flex>
      {children}
    </Flex>
  );
};

const ChevronUp = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginBottom: -6 }}
  >
    <path
      d="M3.13523 8.84197C3.3241 9.04343 3.64052 9.05363 3.84197 8.86477L7.5 5.43536L11.158 8.86477C11.3595 9.05363 11.6759 9.04343 11.8648 8.84197C12.0536 8.64051 12.0434 8.32409 11.842 8.13523L7.84197 4.38523C7.64964 4.20492 7.35036 4.20492 7.15803 4.38523L3.15803 8.13523C2.95657 8.32409 2.94637 8.64051 3.13523 8.84197Z"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    ></path>
  </svg>
);

const ChevronDown = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ marginTop: -6 }}
  >
    <path
      d="M3.13523 6.15803C3.3241 5.95657 3.64052 5.94637 3.84197 6.13523L7.5 9.56464L11.158 6.13523C11.3595 5.94637 11.6759 5.95657 11.8648 6.15803C12.0536 6.35949 12.0434 6.67591 11.842 6.86477L7.84197 10.6148C7.64964 10.7951 7.35036 10.7951 7.15803 10.6148L3.15803 6.86477C2.95657 6.67591 2.94637 6.35949 3.13523 6.15803Z"
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
    ></path>
  </svg>
);

export const ApplicationGroupTag: React.FC<{ app: IApp | IApp2 }> = ({
  app,
}) => {
  if (app.parent) {
    return (
      <Tooltip title="This app inherits its subscription and certificate data from the main app">
        <Tag style={{ margin: 0 }}>{app.parent?.relationshipToParent}</Tag>
      </Tooltip>
    );
  }

  return (
    <Tooltip title="This app shares its subscription and certificate data with children apps">
      <Tag style={{ margin: 0 }}>Main</Tag>
    </Tooltip>
  );
};

const createNewApp = callFirebaseFunction("createNewApp");
