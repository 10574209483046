import * as Sentry from "@sentry/browser";
import React, { Component } from "react";
import { hot } from "react-hot-loader/root";
import { Router } from "react-router-dom";
import "./App.globalCSS";
// import LogRocket from 'logrocket';
// import setupLogRocketReact from 'logrocket-react';
import { Spin } from "antd";
import { history } from "./~reusables/util";
import { AppFieldsProvider } from "./~reusables/contexts/AppFieldsContext";
import { ThemeContextProvider } from "./~reusables/contexts/ThemeContext";
import { InjectStore, store } from "./store";
import {
  initializeOnUserAuth,
  unsubscribeOnUserAuth,
} from "./~reusables/actions";
import WebAppV2 from "./pages/WebAppV2";
import { Flex } from "./components/atoms/Primitives";
import { IUser } from "@todesktop/shared";

const globalAny: any = global;

// if (process.env.NODE_ENV === 'production') {
//   setupLogRocketReact(LogRocket);
//   LogRocket.init('to-desktop/web-app');
// }

interface Window {
  opera?: any;
}

declare let window: Window;

interface IUserLoaded {
  user: IUser;
  userDataLoaded: boolean;
}

class App extends Component {
  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    if (store.getRawState().user?.isImpersonator) return;
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  componentDidMount() {
    initializeOnUserAuth();
  }

  componentWillUnmount() {
    unsubscribeOnUserAuth();
  }

  public render() {
    return (
      <ThemeContextProvider>
        <Router history={history}>
          <>
            <InjectStore
              on={(state) => ({
                user: state.user,
                userDataLoaded: state.userDataLoaded,
              })}
            >
              {({ user, userDataLoaded }: IUserLoaded) => {
                // TODO - investigate why loading user early stopped listener
                // if user loads first, then render app
                // ProtectedRoute is happy because there's a user
                // ProtectedApp will continue to wait until the
                // user's corresponding data has loaded
                if (!userDataLoaded)
                  return (
                    <Flex
                      height="100vh"
                      width="100vw"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Spin size="large" />
                    </Flex>
                  );

                return (
                  <AppFieldsProvider>
                    <WebAppV2 />
                  </AppFieldsProvider>
                );
              }}
            </InjectStore>
          </>
        </Router>
      </ThemeContextProvider>
    );
  }
}

export default hot(App);
