import css from "@emotion/css";
import { Button, message, Modal, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { updateUIState } from "../../~reusables/actions";
import styled, { useTheme } from "../../~reusables/contexts/ThemeContext";
import { Heading } from "../atoms/Heading";
import { Space } from "../atoms/Space";
import {
  getSecurityTokenDetails,
  register,
} from "../../~reusables/firebase/webauthnUtils";

const { Text } = Typography;

const ManageSecurityToken: React.FC = () => {
  const { space } = useTheme();
  const [isModalVisibile, setModalVisibility] = useState(true);
  const [state, setState] = useState<{
    loading: boolean;
    checkingForSecurityToken: boolean;
    token: {
      aaguid: string;
      counter: number;
      icon_dark: string;
      icon_light: string;
      name: string;
    };
  }>({ loading: false, checkingForSecurityToken: true, token: null });

  async function refreshSecurityTokenDetails() {
    setState((prev) => ({ ...prev, checkingForSecurityToken: true }));
    try {
      const secToken = await getSecurityTokenDetails();
      if (secToken) {
        setState((prev) => ({ ...prev, token: secToken }));
      }
    } finally {
      setState((prev) => ({ ...prev, checkingForSecurityToken: false }));
    }
  }

  useEffect(() => {
    refreshSecurityTokenDetails();
  }, []);

  return (
    <StyledModal
      visible={isModalVisibile}
      closable={true}
      centered={true}
      onCancel={() => setModalVisibility(false)}
      afterClose={() => updateUIState(undefined)}
      footer={
        !state.checkingForSecurityToken && !state.token ? (
          <Button
            loading={state.loading}
            type="primary"
            onClick={async () => {
              setState((prev) => ({ ...prev, loading: true }));
              try {
                const token = await register();
                if (token) {
                  setState((prev) => ({ ...prev, token: token }));
                }
              } catch (error) {
                message.error(error.message);
              } finally {
                setState((prev) => ({ ...prev, loading: false }));
              }
            }}
          >
            Register new security token
          </Button>
        ) : (
          <Button disabled>
            Contact support to rotate to a new security token
          </Button>
        )
      }
      css={css`
        overflow: hidden;
      `}
    >
      <Space direction="column">
        <Heading variant="h3" as="h3">
          Manage your Security Token
        </Heading>
        <Text color="support">
          Security tokens are required to release a new version of your app and
          to invite team members.
        </Text>
      </Space>

      <div
        css={css`
          margin: ${space[8]}px 0 ${space[5]}px 0;
        `}
      >
        {state.checkingForSecurityToken ? (
          <div css={{ display: "flex", justifyContent: "center" }}>
            <Spin />
          </div>
        ) : state?.token ? (
          <div
            css={css`
              display: flex;
              align-items: center;
              gap: ${space[6]}px;
            `}
          >
            <img
              src={state.token.icon_light}
              width={32}
              height={32}
              alt="Security Token"
            />
            <div>
              <div>
                <Text strong>{state.token.name ?? "Unknown"}</Text>
              </div>
              <div>
                <Text type="secondary">{state.token.aaguid}</Text>
              </div>
            </div>
          </div>
        ) : (
          <>You do not currently have a security token.</>
        )}
      </div>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  h3,
  p {
    text-align: center;
  }
`;

export default ManageSecurityToken;
