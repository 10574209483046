import { IAppBuildProgress } from "@todesktop/shared";
import { update, IGlobalState, store, selectedApp } from "../../store";
import { Modal } from "antd";
import { callFirebaseFunction } from "../firebase";

export const goToCreateNewAppModal = () => {
  update((draft) => {
    draft.uiState = "create-new-app";
  });
};

export const goToManageSubscriptions = () => {
  update((draft) => {
    draft.uiState = "manage-subscriptions";
  });
};

export const goToManageAccessToken = () => {
  update((draft) => {
    draft.uiState = "manage-access-token";
  });
};

export const goToManageSecurityToken = () => {
  update((draft) => {
    draft.uiState = "manage-security-token";
  });
};

export const goToManageBilling = () => {
  update((draft) => {
    draft.uiState = "manage-billing";
  });
};

export const goToManageAccount = () => {
  update((draft) => {
    draft.uiState = "manage-account";
  });
};

export const goToManageUsers = () => {
  update((draft) => {
    draft.uiState = "manage-users";
  });
};

export const goToAdminLogin = () => {
  update((draft) => {
    draft.uiState = "admin-login";
  });
};

export const updateUIState = (uiState: IGlobalState["uiState"]) => {
  update((draft) => {
    if (uiState === "choose-plan") {
      const state = store.getRawState();
      const { user } = state;
      const app = selectedApp(state);
      const { id, subscription } = app;
      if (app && app.subscription) {
        const modal = Modal.confirm({
          title: "Build a new version?",
          content:
            "Your users will be automatically updated to the new version the next time they open your app.",
          okText: "Start Build",
          maskClosable: true,
          onOk: async () => {
            try {
              await createUpdateBuild(
                user.stripeCustomerId,
                id,
                subscription.subscriptionId
              );
              modal.destroy();
            } catch (err) {
              Modal.error({
                title: "Error",
                content: err.toString(),
              });
            }
          },
        });
        return;
      } else if (user && user.stripeCustomerId) {
        console.log("Go straight to Pay Dialog");
      }
    }
    draft.uiState = uiState;
  });
};

export const removeUIState = () =>
  update((draft) => {
    draft.uiState = null;
  });

const createBuildOnExistingSubscription = callFirebaseFunction(
  "createBuildOnExistingSubscription"
);

export const initialBuildProgressState: IAppBuildProgress = {
  percent: 0,
  message: "Queued",
  type: "progress",
  isBuilding: true,
  shouldSkip: false,
};

function createUpdateBuild(stripeCustomerId, appId, subscriptionId) {
  return createBuildOnExistingSubscription({
    stripeCustomerId,
    appId,
    subscriptionId,
  });
}
