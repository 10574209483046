import React from "react";
import { Store, useStoreState, InjectStoreState } from "pullstate";
import isEmpty from "lodash.isempty";
import { ITeam, IUserTeam } from "./~reusables/types/types";
import { initialBuildState } from "./~reusables/actions/builds";
import {
  Build,
  hasActiveSub,
  hasPlan,
  IApp,
  IUser,
  Plan,
  UserIHaveAcceptedInviteFrom,
  UserIHaveSentInviteTo,
  WindowsEVOnboardingProvider,
} from "@todesktop/shared";

export interface AppUser {
  id: string;
  label: string;
  isOwner: boolean;
}

export interface IGlobalState {
  selectedApp?: IApp["id"];
  selectedTeam?: ITeam; // specific team with its own app collections and so on
  userTeams?: IUserTeam[]; // teams that logged in user is part of (id match)
  user?: IUser;
  invitedUsers: UserIHaveSentInviteTo[];
  acceptedUsers: UserIHaveAcceptedInviteFrom[];
  apps: Array<IApp | never>;
  appToUser: {
    [appId: string]: AppUser;
  };
  buildState: {
    builds: { [key: string]: Build };
    hasBuildsDataLoaded: boolean;
    selectedBuild: Build["id"];
    releasedBuildId: Build["id"];
  };
  liveFields: {
    width?: number;
    height?: number;
    appTitle?: string;
  };
  userDataLoaded: boolean;
  onboarded: boolean;
  shouldCreateSubWithoutBuild: boolean;
  previewScreenshotOS: "windows" | "mac";
  uiState?:
    | "choose-plan"
    | "pay"
    | "subscribe"
    | "pay-todesktop-builder"
    | "build"
    | "convert-login"
    | "create-sub-without-build"
    | "reset-password-on-upgraded-account"
    | "password-signup"
    | "manage-subscriptions"
    | "manage-billing"
    | "new-app"
    | "create-new-app"
    | "new-legacy-app"
    | "manage-access-token"
    | "manage-account"
    | "manage-users"
    | "manage-security-token"
    | "admin-login"
    | null;
}

const isWindows = window.navigator.platform.includes("Win");

export const initialState: IGlobalState = {
  selectedApp: null,
  selectedTeam: null,
  userTeams: [],
  user: null,
  invitedUsers: [],
  acceptedUsers: [],
  onboarded: false,
  userDataLoaded: false,
  previewScreenshotOS: isWindows ? "windows" : "mac",
  shouldCreateSubWithoutBuild: false,
  uiState: null,
  liveFields: {},
  apps: [],
  appToUser: {},
  buildState: initialBuildState,
};

export const store = new Store(initialState);

type getSubstateFn<SS> = (state: IGlobalState) => SS;
export const useStore: <SS>(getSubstate: getSubstateFn<SS>) => SS = (
  substate
) => useStoreState(store, substate);

export const update: (updateFn: (state: IGlobalState) => void) => void = (
  updateFn
) => store.update(updateFn);

export const subscribe: <T>(
  watchFn: (state: IGlobalState) => T,
  listener: (watched: T, allState: IGlobalState, prevWatched: T) => void
) => () => void = (watchFn, listener) => store.subscribe(watchFn, listener);

if (process.env.NODE_ENV === "development") {
  subscribe(
    (state: IGlobalState) => state,
    (state: IGlobalState) => state && console.log({ state })
  );
}

interface IPropsInjectMainStore<SS extends any = any> {
  on?: (state: IGlobalState) => SS;
  children: (output: SS) => React.ReactElement;
}

export const InjectStore: (
  props: IPropsInjectMainStore
) => React.ReactElement = ({ on, children }) => (
  <InjectStoreState store={store} on={on}>
    {(injectedState) => children(injectedState)}
  </InjectStoreState>
);

// STORE REDUCERS
// TODO: Prefix selectors with $ symbol
export const selectedApp = (state: IGlobalState) =>
  selectAppFromID(state.selectedApp)(state);

export const $leaderApp = (state: IGlobalState) => {
  const app = selectedApp(state);
  return app?.parent
    ? state.apps.find(({ id }) => id === app?.parent?.id) || app
    : app;
};

export const isAppOwner = (state: IGlobalState) => {
  return selectedAppUser(state).isOwner === true;
};

export const selectedAppUser = (state: IGlobalState): AppUser => {
  const app = selectAppFromID(state.selectedApp)(state);
  return state.appToUser[app.id];
};

export const selectAppFromID = (id?: IApp["id"]) => (state: IGlobalState) =>
  state.apps.find(
    (app) => typeof app.id === "string" && app.id === (id || state.selectedApp)
  );

export const getLiveField = (name: string) => (state: IGlobalState) => {
  const { liveFields } = state;
  const app = selectedApp(state);
  if (app) {
    const { id } = app;
    return liveFields[`${id}-${name}`];
  }
};

export const appHasActiveSubscription = (state: IGlobalState): boolean => {
  return hasActiveSub($leaderApp(state)?.subscription);
};

export const $completedChecklistItems = (state: IGlobalState) => {
  const app = selectedApp(state);
  return app && app.meta && app.meta.completedChecklistItems
    ? app.meta.completedChecklistItems
    : {};
};

export const $completedEVOnboardingSteps = (state: IGlobalState) => {
  const app = selectedApp(state);
  return app && app.meta && app.meta.windowsEVOnboarding
    ? app.meta.windowsEVOnboarding
    : {};
};

export const $hasPlanAccess = (plan: Plan) => (state: IGlobalState) => {
  const app = $leaderApp(state);
  return hasPlan(plan, app?.subscription);
};

const EVProviderFormatting: {
  [name in WindowsEVOnboardingProvider]: string;
} = {
  globalsign: "GlobalSign",
  other: "Other",
};

export const $windowsEVOnboardingProvider = (
  state: IGlobalState
): { provider: WindowsEVOnboardingProvider; providerFormatted: string } => {
  const app = selectedApp(state);
  const provider =
    app &&
    app.meta &&
    app.meta.windowsEVOnboarding &&
    app.meta.windowsEVOnboarding.provider
      ? app.meta.windowsEVOnboarding.provider
      : WindowsEVOnboardingProvider.globalsign;

  return {
    provider,
    providerFormatted: EVProviderFormatting[provider],
  };
};

export const $macCertExists = (state: IGlobalState) => {
  const { customMacCodeSign, macHsmCertNames } = selectedApp(state);

  const hasHSMCert = Boolean(macHsmCertNames?.mac);
  const hasFallbackCert = customMacCodeSign && !isEmpty(customMacCodeSign);
  return hasHSMCert || hasFallbackCert;
};

export const $windowsCertExists = (state: IGlobalState) => {
  const { customWindowsCodeSign, windowsHsmCertNames } = selectedApp(state);
  const hasFallbackCert =
    customWindowsCodeSign &&
    customWindowsCodeSign.certType &&
    customWindowsCodeSign.hsmCertName &&
    customWindowsCodeSign.hsmCertType;

  const hasHSMCert = Boolean(windowsHsmCertNames?.windows);
  return hasHSMCert || hasFallbackCert;
};

export const $tempHSMCertName = (state: IGlobalState) => {
  const { meta } = selectedApp(state);
  return meta &&
    meta.windowsEVOnboarding &&
    meta.windowsEVOnboarding.tempHSMCertName
    ? meta.windowsEVOnboarding.tempHSMCertName
    : "";
};

export const $shouldMinimizeOnboarding = (state: IGlobalState) => {
  const app = selectedApp(state);

  if (app && app.meta && app.meta.completedChecklistItems) {
    return !!app.meta.completedChecklistItems.shouldMinimizeOnboarding;
  }

  return false;
};

export const appIsBuilding = (state: IGlobalState): boolean => {
  const app = selectedApp(state);
  if (!app) {
    return false;
  }
  const meta = app.meta;
  const currentBuildProgress = meta && (meta.currentBuildProgress as any);
  if (!currentBuildProgress) {
    return false;
  }
  if (currentBuildProgress.mac && currentBuildProgress.mac.isBuilding) {
    return true;
  }
  if (currentBuildProgress.windows && currentBuildProgress.windows.isBuilding) {
    return true;
  }
  if (currentBuildProgress.linux && currentBuildProgress.linux.isBuilding) {
    return true;
  }
  return false;
};
