import React from "react";
import { Space } from "antd";
import { Heading } from "../atoms/Heading";
import { Box } from "../atoms/Primitives";
import { Text } from "../atoms/Text";

interface UsersListProps {
  title: string;
  description: React.ReactNode;
}

export const UsersList: React.FC<UsersListProps> = ({
  title,
  description,
  children,
}) => {
  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Box>
        <Heading as="h5" variant="h5">
          {title}
        </Heading>
        <Text variant="body3">{description}</Text>
      </Box>
      {children}
    </Space>
  );
};
