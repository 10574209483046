import {
  CustomMacNotarization,
  CustomNotarizationApiKeyAuth,
  CustomNotarizationPasswordHsmAuth,
} from "@todesktop/shared";
import { Button, Form, message, Radio, Typography } from "antd";
import Upload, { UploadChangeParam } from "antd/lib/upload";
import React, { useEffect, useState } from "react";
import { selectedApp, useStore } from "../../../store";
import {
  isFileChangeError,
  toBase64,
  updateMacNotarization,
} from "../../../~reusables/actions";
import useForm from "../../../~reusables/hooks/useForm";
import { CertPlatform } from "../../../~reusables/types";
import { track } from "../../../~reusables/util/analytics";
import {
  macNotarizationApiKeyEditValidation,
  macNotarizationApiKeyValidation,
  macNotarizationPasswordEditValidation,
  macNotarizationPasswordValidation,
} from "../../../~reusables/util/validationRules";
import { FormInput, FormItem } from "../../atoms/FormUtils";
import { Box, Flex } from "../../atoms/Primitives";
import QuestionTooltip from "../../atoms/QuestionTooltip";
import { Space } from "../../atoms/Space";
import { TooltipLink } from "../../atoms/TextUtils";
import { genFormEvent } from "./SharedCertUtils";

export const MacNotarizationForm: React.FC<{
  onNotarizationUpdate: () => void;
}> = ({ onNotarizationUpdate }) => {
  const { customNotarization } = useStore(selectedApp);
  const [authType, setAuthType] = React.useState<AuthType>(
    getAuthType(customNotarization)
  );

  return (
    <>
      <Typography.Title level={5}>Auth type:</Typography.Title>
      <Box paddingBottom={15}>
        <Radio.Group
          onChange={(e) => setAuthType(e.target.value)}
          options={[
            { label: "User / password", value: "password" },
            { label: "App Store Connect API Key", value: "apiKey" },
          ]}
          value={authType}
        />
      </Box>

      {authType === "password" && (
        <PasswordForm onNotarizationUpdate={onNotarizationUpdate} />
      )}

      {authType === "apiKey" && (
        <ApiKeyForm onNotarizationUpdate={onNotarizationUpdate} />
      )}
    </>
  );
};

const PasswordForm: React.FC<{
  onNotarizationUpdate: () => void;
}> = ({ onNotarizationUpdate }) => {
  const { id, customNotarization } = useStore(selectedApp);
  const passwordNotarization = customNotarization as CustomNotarizationPasswordHsmAuth;
  const MIN_LABEL_WIDTH = 200;

  const isEditMode = Boolean(customNotarization?.type === "hsm");

  const form = useForm(
    {
      appleId: passwordNotarization?.appleId || "",
      appleIdPassword: "",
      teamId: passwordNotarization?.teamId || "",
    },
    async (formData) => {
      track({
        event: "Update Certificate",
        properties: {
          type: "notarization",
          action: "save",
          platform: CertPlatform.Mac,
        },
      });
      await updateMacNotarization(id, formData.appleIdPassword, {
        appleId: formData.appleId,
        teamId: formData.teamId,
        type: "hsm",
      });
      onNotarizationUpdate();
      message.success("Mac Notarization Credentials saved");
    },
    isEditMode
      ? macNotarizationPasswordEditValidation
      : macNotarizationPasswordValidation
  );

  useEffect(() => {
    if (form.formError) {
      message.error(form.formError);
    }
  }, [form.formError]);

  return (
    <Form layout="vertical" onFinish={form.handleSubmit}>
      <FormInput
        error={form.errors.appleId}
        label={<Space minWidth={MIN_LABEL_WIDTH}>Apple ID</Space>}
        name="appleId"
        onChange={form.handleChange}
        value={form.values.appleId}
        disabled={form.isSubmitting}
      />
      <FormInput
        error={form.errors.teamId}
        label={
          <Space minWidth={MIN_LABEL_WIDTH}>
            Team ID
            <QuestionTooltip
              text={
                <>
                  A Team ID is a unique 10-character string generated by Apple
                  that’s assigned to your team.
                  <br />
                  <br />
                  Read about{" "}
                  <TooltipLink
                    href={
                      "https://developer.apple.com/help/account/manage-your-team/locate-your-team-id/"
                    }
                    target="_blank"
                  >
                    how to locate your Team ID
                  </TooltipLink>
                  .
                </>
              }
            />
          </Space>
        }
        name="teamId"
        onChange={form.handleChange}
        value={form.values.teamId}
        disabled={form.isSubmitting}
      />
      <FormInput
        error={form.errors.appleIdPassword}
        label={
          <Space minWidth={MIN_LABEL_WIDTH}>
            App Specific Password
            <QuestionTooltip
              text={
                <>
                  App-specific passwords allow you to securely notarize your app
                  and easily revoke the password if needed.
                  <br />
                  <br />
                  Read about{" "}
                  <TooltipLink
                    href={"https://support.apple.com/en-ie/HT204397"}
                    target="_blank"
                  >
                    how to generate an app-specific password
                  </TooltipLink>
                  .
                </>
              }
            />
          </Space>
        }
        name="appleIdPassword"
        onChange={form.handleChange}
        value={form.values.appleIdPassword}
        disabled={form.isSubmitting}
        placeholder="Leave empty if you wish to keep your existing password"
        password
      />
      <Flex justifyContent="center">
        <Button
          htmlType="submit"
          loading={form.isSubmitting}
          size="large"
          type="primary"
        >
          Save changes
        </Button>
      </Flex>
    </Form>
  );
};

const ApiKeyForm: React.FC<{
  onNotarizationUpdate: () => void;
}> = ({ onNotarizationUpdate }) => {
  const { id, customNotarization } = useStore(selectedApp);
  const apiKeyNotarization = customNotarization as CustomNotarizationApiKeyAuth;
  const [saveError, setSaveError] = useState<string>();
  const MIN_LABEL_WIDTH = 160;

  const isEditMode = Boolean(
    apiKeyNotarization?.appleApiIssuer && apiKeyNotarization?.appleApiKeyId
  );

  const onSave = async (formData: ApiKeyForm) => {
    track({
      event: "Update Certificate",
      properties: {
        type: "notarization",
        action: "save",
        platform: CertPlatform.Mac,
      },
    });
    setSaveError(undefined);

    try {
      await updateMacNotarization(id, formData.apiKeyBase64, {
        appleApiKeyId: formData.appleApiKeyId,
        appleApiIssuer: formData.appleApiIssuer,
        type: "hsm-api",
      });
      onNotarizationUpdate();
      message.success("Mac Notarization Credentials saved");
    } catch (e) {
      console.log(e);
      const errorMessage = e instanceof Error ? e.message : JSON.stringify(e);
      setSaveError(errorMessage);
      message.error(errorMessage);
    }
  };

  const form = useForm(
    {
      apiKeyBase64: "",
      appleApiKeyId: apiKeyNotarization?.appleApiKeyId || "",
      appleApiIssuer: apiKeyNotarization?.appleApiIssuer || "",
    } as ApiKeyForm,
    onSave,
    isEditMode
      ? macNotarizationApiKeyEditValidation
      : macNotarizationApiKeyValidation
  );

  const onApiKeyFileSelected = ({ file }: UploadChangeParam) => {
    if (!file || isFileChangeError(file)) {
      return;
    }

    if (
      file.name &&
      file.name.includes("AuthKey_") &&
      !form.values.appleApiKeyId
    ) {
      const [, appleApiKeyId] = file.name.match(/AuthKey_(\w+)\.p8/) || [];
      if (appleApiKeyId) {
        form.handleChange(genFormEvent("appleApiKeyId", appleApiKeyId));
      }
    }

    toBase64((file as unknown) as Blob)
      .then((base64) => {
        form.handleChange(genFormEvent("apiKeyBase64", base64));
      })
      .catch((err) => message.error(err.message, 3));
  };

  useEffect(() => {
    if (form.formError) {
      message.error(form.formError);
    }
  }, [form.formError]);

  return (
    <Form layout="vertical" onFinish={form.handleSubmit}>
      <FormItem
        error={saveError || form.errors.apiKeyBase64}
        label={
          <Space minWidth={MIN_LABEL_WIDTH}>
            Apple Api Key (
            <i>
              <code>p8</code>
            </i>
            )
          </Space>
        }
        marginBottom="12px"
      >
        <Upload
          onChange={onApiKeyFileSelected}
          accept=".p8"
          maxCount={1}
          beforeUpload={() => false}
        >
          <Button>Select File</Button>
        </Upload>
      </FormItem>
      <FormInput
        error={form.errors.appleApiKeyId}
        label={
          <Space minWidth={MIN_LABEL_WIDTH}>
            Apple Api Key Id
            <QuestionTooltip
              text={
                <>
                  When you download an API Key file it comes with the name of
                  AuthKey_%appleApiKeyId%.p8.
                </>
              }
            />
          </Space>
        }
        name="appleApiKeyId"
        onChange={form.handleChange}
        value={form.values.appleApiKeyId}
        disabled={form.isSubmitting}
      />
      <FormInput
        error={form.errors.appleApiIssuer}
        label={
          <Space minWidth={MIN_LABEL_WIDTH}>
            Apple Api Issuer
            <QuestionTooltip
              text={
                <>
                  Your App Store Connect API key issuer, for example,
                  c055ca8c-e5a8-4836-b61d-aa5794eeb3f4.
                </>
              }
            />
          </Space>
        }
        name="appleApiIssuer"
        onChange={form.handleChange}
        value={form.values.appleApiIssuer}
        disabled={form.isSubmitting}
      />
      <Flex justifyContent="center">
        <Button
          htmlType="submit"
          loading={form.isSubmitting}
          size="large"
          type="primary"
        >
          Save changes
        </Button>
      </Flex>
    </Form>
  );
};

function getAuthType(options: CustomMacNotarization): AuthType {
  if (options && "type" in options && options.type === "hsm-api") {
    return "apiKey";
  }

  return "password";
}

type AuthType = "password" | "apiKey";

interface ApiKeyForm {
  apiKeyBase64: string;
  appleApiKeyId: string;
  appleApiIssuer: string;
}
