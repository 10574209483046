import type firebase from "firebase/app";
import { getData, getCollectionSnapshotWhere, deleteDoc } from "./common";
import { firestore } from "./firestore";
import { getCurrentUser } from "./auth";
import { UserIHaveSentInviteTo } from "@todesktop/shared";
import { DeepPartial } from "../types";

export const getInvitedUserData = (id: string, email: string) =>
  getData<UserIHaveSentInviteTo>(
    firestore.doc(`/users/${id}/invitedUsers/${email}`)
  );

export const getInvitedUsers = (id: string) =>
  firestore.collection(`users/${id}/invitedUsers`);

export const onInvitedUsersUpdate = (
  id: string,
  handler: (
    arg: UserIHaveSentInviteTo[],
    snapshot?: firebase.firestore.QuerySnapshot
  ) => void
) => {
  if (id) {
    return getCollectionSnapshotWhere<UserIHaveSentInviteTo>(
      getInvitedUsers(id),
      { orderBy: { fieldPath: "email", direction: "desc" } },
      handler
    );
  }
};

const getInvitedUser = (id: string, email: string) =>
  firestore.doc(`users/${id}/invitedUsers/${email}`);

export const deleteInvitedUser = async (email: string) => {
  const userId = getCurrentUser().uid;
  await deleteDoc(getInvitedUser(userId, email));
};

export const updateInvitedUser = async (
  email: string,
  user: DeepPartial<Pick<UserIHaveSentInviteTo, "permissions">>
) => {
  const userId = getCurrentUser().uid;
  await getInvitedUser(userId, email).set({ ...user }, { merge: true });
};
