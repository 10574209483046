import React, { useRef } from "react";
import { Row, Col, Form, Input, Button, Popover, message } from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import css from "@emotion/css";
import LoginButton from "./LoginButton";
import {
  signInWithPassword,
  sendPasswordResetEmail,
} from "../../~reusables/firebase"; // TODO use action (direct access to firebase layer)
import { useState, ChangeEvent } from "react";
import { useInput } from "react-hanger";

export interface ILoginModalInnerProps {
  onGoogleAuth?(isLogin?: boolean): void;
  onGithubAuth?(isLogin?: boolean): void;
  onTwitterAuth?(isLogin?: boolean): void;
  onPasswordAuthCallback?(): void;
}

const LoginModalInner: React.SFC<ILoginModalInnerProps> = ({
  onGoogleAuth,
  onGithubAuth,
  onTwitterAuth,
  onPasswordAuthCallback,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<Error>(null);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const forgotPasswordInputRef = useRef(null);
  const popoverRef = useRef(null);
  const forgotPasswordInput = useInput("");
  const [isForgottenPasswordErrored, setForgottenPasswordError] = useState(
    false
  );
  const [
    isRequestingForgottenPassword,
    setRequestingForgottenPassword,
  ] = useState(false);

  const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setPassword(value);
  };

  const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setEmail(value);
  };

  const onKeypress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      signIn();
    }
  };

  const signIn = async () => {
    if (isSubmiting) {
      return;
    }
    try {
      setIsSubmiting(true);
      await signInWithPassword(email, password);
      if (onPasswordAuthCallback) {
        onPasswordAuthCallback();
      }
    } catch (error) {
      // TODO: Check if error matches then check if user is old account
      // If old account then forward them to old login page
      setError(error.message);
    }
    setIsSubmiting(false);
  };

  const showForgotPasswordPopover = (isVisible) => {
    if (isVisible) {
      setTimeout(() => {
        // setTimeout hack to make sure ref is populated
        if (forgotPasswordInputRef.current) {
          forgotPasswordInputRef.current.focus();
        }
      }, 50);
    }
  };

  const submitForgottenPasswordRequest = async (e) => {
    setRequestingForgottenPassword(true);
    setForgottenPasswordError(false);
    e.preventDefault();
    const resetEmail = forgotPasswordInput.value;
    try {
      await sendPasswordResetEmail(resetEmail);
      message.success(
        `Reset password email sent to ${resetEmail}. Check your inbox for an email from us.`
      );
      if (popoverRef && popoverRef.current.tooltip) {
        popoverRef.current.tooltip.setState({ visible: false });
      }
    } catch (err) {
      message.error(err.toString());
      setForgottenPasswordError(true);
    }
    setRequestingForgottenPassword(false);
  };

  return (
    <Row gutter={12}>
      <Col span={12}>
        <div
          css={css`
            && {
              background: #f5f5f5;
              border: 1px #eee solid;
              border-radius: 3px;
              padding: 10px;
              margin: 0 auto;
            }
            .ant-form-item {
              margin-bottom: 10px;
            }
          `}
        >
          <Form.Item validateStatus={error ? "error" : null}>
            <Input
              prefix={<MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="you@domain.com"
              onChange={onEmailChange}
              value={email}
              onKeyPress={onKeypress}
            />
          </Form.Item>
          <Form.Item
            help={error}
            validateStatus={error ? "error" : null}
            css={css`
              && .ant-form-explain {
                font-size: 12px;
              }
            `}
          >
            <Input.Password
              prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              type="password"
              placeholder="Password"
              onChange={onPasswordChange}
              value={password}
              onKeyPress={onKeypress}
            />
          </Form.Item>
          <div
            css={css`
              text-align: center;
            `}
          >
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              onClick={signIn}
              loading={isSubmiting}
            >
              Log in
            </Button>
            <div
              css={css`
                margin-top: 10px;
              `}
            >
              <Popover
                ref={popoverRef}
                onVisibleChange={showForgotPasswordPopover}
                content={
                  <form
                    css={css`
                      .ant-form-item {
                        margin-bottom: 0;
                      }
                      .ant-form-item-control {
                        line-height: unset;
                      }
                    `}
                    onSubmit={submitForgottenPasswordRequest}
                  >
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          validateStatus={
                            isForgottenPasswordErrored ? "error" : null
                          }
                          hasFeedback={true}
                        >
                          <Input
                            type="email"
                            required={true}
                            placeholder="you@domain.com"
                            ref={forgotPasswordInputRef}
                            onChange={forgotPasswordInput.onChange}
                            value={forgotPasswordInput.value}
                            prefix={
                              <MailOutlined
                                style={{ color: "rgba(0,0,0,.25)" }}
                              />
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Button
                          loading={isRequestingForgottenPassword}
                          type="primary"
                          block={true}
                          htmlType="submit"
                        >
                          Reset My Password
                        </Button>
                      </Col>
                    </Row>
                  </form>
                }
                title="Enter your email address"
                trigger="click"
              >
                <a className="login-form-forgot">Forgot password</a>
              </Popover>
            </div>
          </div>
        </div>
      </Col>
      <Col
        span={12}
        style={{ display: "flex", flexDirection: "column", gap: "12px" }}
      >
        <LoginButton kind="google" onClick={() => onGoogleAuth(true)}>
          Login With Google
        </LoginButton>
        <LoginButton kind="github" onClick={() => onGithubAuth(true)}>
          Login With Github
        </LoginButton>
        <LoginButton kind="x" onClick={() => onTwitterAuth(true)}>
          Login With X
        </LoginButton>
      </Col>
    </Row>
  );
};

export default LoginModalInner;
