import { CopyOutlined } from "@ant-design/icons";
import css from "@emotion/css";
import { Button, Input, message, Modal, Typography } from "antd";
import copy from "copy-to-clipboard";
import React, { useState } from "react";
import { useStore } from "../../store";
import { updateUIState } from "../../~reusables/actions";
import { hrefLinks } from "../../~reusables/constants";
import styled, { useTheme } from "../../~reusables/contexts/ThemeContext";
import { callFirebaseFunction } from "../../~reusables/firebase";
import { Heading } from "../atoms/Heading";
import { Space } from "../atoms/Space";

const { Text } = Typography;

const ManageAccessToken: React.FC = () => {
  const { space } = useTheme();
  const user = useStore((state) => state.user);
  const [isModalVisibile, setModalVisibility] = useState(true);
  const [state, setState] = useState({ loading: false, token: "" });

  return (
    <StyledModal
      visible={isModalVisibile}
      closable={true}
      centered={true}
      onCancel={() => setModalVisibility(false)}
      afterClose={() => updateUIState(undefined)}
      footer={
        <Button
          loading={state.loading}
          type="primary"
          onClick={async () => {
            setState((prev) => ({ ...prev, loading: true }));

            try {
              const token = await generateAccessToken();
              setState((prev) => ({ ...prev, token }));
            } finally {
              setState((prev) => ({ ...prev, loading: false }));
            }
          }}
        >
          Generate access token
        </Button>
      }
      css={css`
        overflow: hidden;
      `}
    >
      <Space direction="column">
        <Heading variant="h3" as="h3">
          Manage your Access Token
        </Heading>
        <Text color="support">
          Access tokens are used to interact with the{" "}
          <a
            href={hrefLinks.cliNpmDocs}
            target="_blank"
            rel="noopener noreferrer"
          >
            ToDesktop CLI
          </a>
          .
        </Text>
      </Space>

      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 12px;
          margin: ${space[8]}px ${space[6]}px ${space[5]}px ${space[6]}px;
        `}
      >
        <Input
          addonAfter={
            <CopyOutlined
              disabled={!state.token}
              onClick={() => {
                if (!state.token) return;

                copy(state.token);
                message.success("Token has been copied to your clipboard.");
              }}
            />
          }
          value={state.token}
        />
        <Text style={{ textAlign: "center" }}>
          {state.token
            ? "Please store the token securely. The token will no longer be visible once you exit this modal."
            : user?.accessToken
            ? "Token has already been generated. Generating a new token will revoke the old one."
            : "Generate an access token to use the ToDesktop CLI. Please store these safely as they will only be shown for a brief moment."}
        </Text>
      </div>
    </StyledModal>
  );
};

async function generateAccessToken(): Promise<string> {
  const { data } = await callFirebaseFunction("generateAccessToken")({
    returnUrl: location.href,
  });

  return data.accessToken;
}

const StyledModal = styled(Modal)`
  h3,
  p {
    text-align: center;
  }
`;

export default ManageAccessToken;
